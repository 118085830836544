import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import Button from 'components/Button';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { richTextElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBackgroundColor,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  getThemeSimulatorColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';
import { ISimulatorProps } from './types';
import { themev2 } from 'theme-v2';

export const Wrapper = styled.section<ITheme & ISimulatorProps>(
  ({ background, ...props }) => ({
    background,
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      display: CSS_DISPLAY.NONE,
    },
  })
);

const TitlesContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  [richTextElements]: {
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
  'h1, h2, h3:first-of-type': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  h3: {
    fontWeight: '500',
    fontSize: themev2.fonts.size.h4,
  },
  p: {
    textAlign: 'center',
  },
}));

export const ScraperContainer = styled.div<ITheme>(() => ({
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  height: '620px',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  borderRadius: '12px',
  [MEDIA_QUERIES.TABLET]: {
    height: 'auto',
    width: '732px',
  },
}));

export const ScraperImageContainer = styled.div<ITheme>((props) => ({
  width: '100%',
  display: CSS_DISPLAY.FLEX,
  backgroundColor: getThemeMainColor(props),
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px',
  div: {
    height: '100%',
    width: 'auto',
    margin: '0 auto',
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '366px',
  },
}));

export const ScraperCodeContainer = styled.div<ITheme>((props) => ({
  ...flexColContainerStyles,
  width: '100%',
  backgroundColor: getThemeMainColor(props, MAIN_COLOR.BLACK),
  borderTopRightRadius: '12px',
  borderBottomRightRadius: '12px',
  [MEDIA_QUERIES.TABLET]: {
    width: '366px',
  },
}));

export const SearchContainer = styled.div<ITheme>(() => ({
  ...flexColContainerStyles,
  width: '100%',
  padding: '20px 20px 0 20px',
  [MEDIA_QUERIES.TABLET]: {
    padding: '12px 20px 0 20px',
  },
}));

export const JsonContainer = styled.div<ITheme>((props) => ({
  ...flexColContainerStyles,
  width: '100%',
  height: '463px',
  padding: '24px 0 20px 0',
  backgroundColor: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  borderTop: '1px solid hsla(0,0%,100%,.2)',
  borderBottomRightRadius: '12px',
  [MEDIA_QUERIES.TABLET]: {
    height: 'auto',
    padding: 0,
  },
}));

export const SearchTitle = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  fontFamily: getThemeFontFamily(props),
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  width: '100%',
}));

export const SearchField = styled.div<ITheme>(() => ({
  ...flexColContainerStyles,
  width: '100%',
  position: CSS_POSITION.RELATIVE,
}));

const JsonTitle = styled.div<ITheme>((props) => ({
  marginRight: '46px',
  fontFamily: getThemeFontFamily(props),
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  paddingLeft: '20px',
}));

export const JsonCta = styled(Button)<ITheme & ISimulatorProps>(
  ({ isFormVisible, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    alignItems: CSS_ALIGN.CENTER,
    height: '32px',
    width: 'auto',
    marginRight: '20px',
    padding: '9px 15px',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    border: `1px solid ${getThemeMainColor(props)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    backgroundColor: isFormVisible
      ? getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY)
      : getThemeMainColor(props),
    fontFamily: getThemeFontFamily(props),
    color: isFormVisible
      ? getThemeMainColor(props)
      : getThemeNeutralColor(props, COLOR_SHADE.DEEP),
    fontWeight: 500,
    textAlign: TEXT_ALIGN.CENTER,
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    [DOM_STATE.HOVER]: {
      border: `2px solid ${getThemeMainColor(props)}`,
      padding: '8px 14px',
    },
    [MEDIA_QUERIES.TABLET]: {
      width: 'auto',
    },
  })
);

const JsonCodeField = styled.pre<ITheme>((props) => ({
  ...flexColContainerStyles,
  width: '502px',
  color: getThemeSimulatorColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  height: '430px',
  paddingLeft: '20px',
  overflow: 'scroll',
  overflowX: 'hidden',
  overflowWrap: 'break-word',
  scrollbarGutter: 'stable',
  position: 'relative',
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: getThemeSimulatorColor(props),
  },
  '::-webkit-scrollbar-track': {
    marginRight: '10px',
  },
  span: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    textAlign: TEXT_ALIGN.LEFT,
  },
  '& span:nth-of-type(2)': {
    color: getThemeMainColor(props),
    backgroundColor: 'rgba(44,150,247,.4)',
  },
  div: {
    display: 'none !important',
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '356px',
    height: '280px',
  },
}));

export const CodeBlockContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '472px',
  height: '68px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  border: '1px solid hsla(0,0%,100%,.2)',
  backgroundColor: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  [MEDIA_QUERIES.TABLET]: {
    width: '326px',
  },
}));

export const StyledCodeBlock = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '384px',
  padding: '2px 0px 0px 24px',
  overflowX: 'scroll',
  scrollbarGutter: 'stable',
  '::-webkit-scrollbar': {
    height: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: getThemeSimulatorColor(props),
  },
  '::-webkit-scrollbar-track': {
    marginLeft: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '258px',
  },
}));

const Popup = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  width: 'auto',
  height: 'auto',
  padding: `5px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
  backgroundColor: getThemeSimulatorColor(props, COLOR_SHADE.SECONDARY),
  color: getThemeMainColor(props),
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  position: CSS_POSITION.ABSOLUTE,
  right: '9px',
  top: '-35px',
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  svg: {
    paddingRight: '5px',
    path: {
      fill: getThemeMainColor(props),
    },
  },
}));

export const StyledCopyButton = styled(Button)<ITheme>((props) => ({
  margin: 0,
  backgroundColor: 'rgba(44, 150, 247, 0.2)',
  width: '50px',
  height: '50px',
  padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  backgroundImage:
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDBIMTZDMTguMjEgMCAyMCAxLjc5IDIwIDRWMTZDMjAgMTYuNTUgMTkuNTUgMTcgMTkgMTdDMTguNDUgMTcgMTggMTYuNTUgMTggMTZWNEMxOCAyLjkgMTcuMSAyIDE2IDJINkM1LjQ1IDIgNSAxLjU1IDUgMUM1IDAuNDUgNS40NSAwIDYgMFpNMiA1SDEzQzE0LjEgNSAxNSA1LjkgMTUgN1YyMEMxNSAyMS4xIDE0LjEgMjIgMTMgMjJIMkMwLjkgMjIgMCAyMS4xIDAgMjBWN0MwIDUuOSAwLjkgNSAyIDVaTTIgMjBIMTNWN0gyVjIwWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  border: 'none',
  [DOM_STATE.HOVER]: {
    backgroundColor: getThemeSimulatorColor(props, COLOR_SHADE.SECONDARY),
    padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    border: 'none',
  },
}));

export const CopyButtonContainer = styled.div({
  maxWidth: '1024px',
  width: 'auto',
  margin: '0 auto',
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  position: CSS_POSITION.ABSOLUTE,
  top: '9px',
  right: '9px',
});

export const CodeField = styled.pre<ITheme>((props) => ({
  color: getThemeMainColor(props),
  width: '100%',
  height: '100%',
  margin: '4px 0 10px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  display: CSS_DISPLAY.INLINE_BLOCK,
  whiteSpace: CSS_FLEX_WRAP.NOWRAP,
  div: {
    display: 'none !important',
  },
}));

export const JsonContainerTop = styled.div<ITheme & ISimulatorProps>(
  ({ isTitleVisible, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    width: '100%',
    height: 'auto',
    margin: `0 0 ${themev2.spacings.XS}`,
    justifyContent: isTitleVisible
      ? CSS_JUSTIFY_CONTENT.SPACE_BETWEEN
      : CSS_JUSTIFY_CONTENT.FLEX_END,
    alignItems: CSS_ALIGN.CENTER,
    backgroundColor: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: 0,
    },
  })
);

const FormContainer = styled.div<ITheme>(() => ({
  display: CSS_DISPLAY.FLEX,
  width: '512px',
  height: '430px',
  padding: '0 100px 20px',
  [MEDIA_QUERIES.TABLET]: {
    height: '280px',
    width: 'auto',
    padding: '0 60px 20px',
  },
}));

const FormBlock = styled.div<ITheme>(() => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  alignItems: CSS_ALIGN.CENTER,
  width: '100%',
  marginBottom: '15px',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.FLEX_START,
  },
}));

export const FormComponent = styled.form({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
});

export const ValidationMessage = styled.p<ITheme>((props) => ({
  display: 'inline-block',
  color: getThemeSimulatorColor(props, COLOR_SHADE.SECONDARY),
  height: '20px',
  fontFamily: getThemeFontFamily(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  margin: '8px 0',
}));

export const FormLabel = styled.label<ITheme>((props) => ({
  display: CSS_DISPLAY.BLOCK,
  marginBottom: '5px',
  fontFamily: getThemeFontFamily(props),
  color: getThemeMainColor(props),
  fontWeight: 400,
  [MEDIA_QUERIES.TABLET]: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  },
}));

export const FormInput = styled.input<ITheme>((props) => ({
  display: CSS_DISPLAY.BLOCK,
  width: '100%',
  height: '42px',
  padding: '8px 12px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  color: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  border: `1px solid ${getThemeSimulatorColor(props, COLOR_SHADE.DEEP)}`,
  [MEDIA_QUERIES.TABLET]: {
    height: '32px',
  },
}));

export const FormSubmitButton = FormInput.withComponent('button');

export const FormButton = styled(FormSubmitButton)<ITheme & ISimulatorProps>(
  ({ submitButtonEnabled, ...props }) => ({
    color: getThemeMainColor(props),
    marginBottom: 0,
    marginTop: '18px',
    padding: '9px 15px',
    backgroundColor: !submitButtonEnabled
      ? getThemeSimulatorColor(props)
      : getThemeSimulatorColor(props, COLOR_SHADE.SECONDARY),
    border: 'none',
    cursor: !submitButtonEnabled ? 'not-allowed' : 'pointer',
    fontFamily: getThemeFontFamily(props),
    ':hover': {
      backgroundColor: !submitButtonEnabled
        ? getThemeSimulatorColor(props)
        : 'rgba(56,152,236,.9)',
    },
    [MEDIA_QUERIES.TABLET]: {
      marginTop: 0,
      padding: 0,
    },
  })
);

export const FormTitle = styled.div<ITheme>((props) => ({
  fontFamily: getThemeFontFamily(props),
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  marginBottom: '22px',
  [MEDIA_QUERIES.TABLET]: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));

const MessageContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  width: '100%',
  color: getThemeMainColor(props),
  fontFamily: getThemeFontFamily(props),
  paddingBottom: '24px',
  svg: {
    width: '40px',
    height: '40px',
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const MessageStatus = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  fontWeight: 500,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
}));

export const MessageText = styled.p<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  textAlign: TEXT_ALIGN.CENTER,
}));

export const HideableTitles = hideable(TitlesContainer);
export const HideableJsonCodeField = hideable(JsonCodeField);
export const HideableFormContainer = hideable(FormContainer);
export const HideableFormBlock = hideable(FormBlock);
export const HideableMessageContainer = hideable(MessageContainer);
export const HideableJsonTitle = hideable(JsonTitle);
export const HideablePopup = hideable(Popup);
